import { Component,Output,EventEmitter,Input } from '@angular/core';
import { Sortingfield } from '../../../enums/sortingfield';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss']
})
export class SortingComponent {
  @Output() sortingType:EventEmitter<object>= new EventEmitter(); 
  @Input() isActive: boolean =false;
  @Input() sortType :any; 
  @Input() colName: any=Sortingfield.bookId;
  @Output() popUpMouseOut:EventEmitter<object>= new EventEmitter(); 
  @Input() data: any[];

  ngOnInit(){
console.log("sortType",this.sortType)
console.log("data",this.data)
}

  sendValues(val:any){
    this.sortingType.emit({value : val,col:this.colName});    
 } 

 closePopUp(){
  this.popUpMouseOut.emit(this.colName);
 }

}
