import { Injectable } from '@angular/core';
import { WindowRef } from './window-ref.service';

// declare let ga:any; 
// declare let gtag:any; 
@Injectable({
  providedIn: 'root'
})

export class GoogleAnaylticsService {
    pageTrackingData:any = {} 
  constructor(private _windowRef: WindowRef) { }

   
  public sendPageTrackingLog(arData: any) {
    let arPageTracing = {
        eventCategory: arData.eventCategory || '',
        userLogin: arData.user_login || '',
        userCompany: arData.user_charterer || '',
        userEmail: arData.user_email || '',
        userName: arData.user_name || '',
        eventAction:arData.eventAction || '',
        eventLabel:arData.eventLabel || '',
        eventValue:arData.eventValue || '',
        userType:arData.userType || ''
    };
    this.addGoogleTagsLog(arPageTracing);
}

public addGoogleTagsLog(arData: any) {
    if (arData.eventCategory) {
        let arDataLayer = arData;
        arDataLayer.event = arData.eventCategory;
        this._windowRef.nativeWindow.dataLayer.push(arDataLayer);
    }
}

}





