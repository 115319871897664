import { Pipe, PipeTransform } from '@angular/core';
export type SortOrder = 'asc' | 'desc';
@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
 
  transform(value: any[], sortOrder: SortOrder | string = 'asc', sortKey?: string): any {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);
    if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) return value;

    let numberArray = [];
    let stringArray = [];

    if (!sortKey ) {
      numberArray = value.filter(item => typeof item === 'number').sort();
      stringArray = value.filter(item => typeof item === 'string').sort();
    } else {
      if(sortKey =='latestETA' || sortKey =='dischargePortEta'){
      
        stringArray = value;
      }else if(sortKey == 'dtCompletedOn'){
        //  stringArray=sortByDate(value,sortKey);
        stringArray = value;
      }else{
        numberArray = value.filter(item => typeof item[sortKey] === 'number').sort((a, b) => a[sortKey] - b[sortKey]);
     
        stringArray = value
        .filter(item => typeof item[sortKey] === 'string')
        .sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return -1;
          else if (a[sortKey] > b[sortKey]) return 1;
          else return 0;
        });
      }
      
    }
    const sorted = numberArray.concat(stringArray);
    if(sortKey == "idBooking"){
      return sortOrder === 'asc' ? sorted : sorted.reverse();
    } else {
      return sorted;
    }
    
  }
}

function vessalArray(value: any[], sortKey: string,typ:string): any[] {
  return value.sort((a: any, b: any) => {
    // equal items sort equally
   
  // nulls sort after anything else
   if (a[sortKey] === null) {
      return 1;
  }
  else if (b[sortKey] === null) {
      return -1;
  }
 
  //  if we're ascending, lowest sorts first
  else if (typ =="asc") {
    let date1 = new Date(a[sortKey]);
    let date2 = new Date(b[sortKey]);
      if(date2)
        return 0;
      else
        if(date1 < date2)
          return -1;
        else
          return 1

  }
  // if descending, highest sorts first
  else { 
    let date1 = new Date(a[sortKey]);
    let date2 = new Date(b[sortKey]);
      if(date2)
        return 0;
      else
        if(date1< date2)
          return 1
        else
          return -1;

  }
});
}

function sortByDate(value: any[], sortKey: string): any[] {
  return value.sort((a: any, b: any) => {
    let date1 =(sortKey == 'dtCompletedOn')? new Date(a.dtCompletedOn) : new Date(a['arVessel'][0][sortKey]);
    let date2 = (sortKey == 'dtCompletedOn')? new Date(b.dtCompletedOn):new Date(b['arVessel'][0][sortKey]);
    if (date1 > date2) {
        return 1;
    } else if (date1 < date2) {
        return -1;
    } else {
        return 0;
    }
});
}


