
<!-- Showing Result Area -->
<div class="ShowingResultMain">
    <p class="notfymesge" *ngIf="searchListing.length > 9">There are more than 10 records found, please <a href="javascript:void(0)" [routerLink]="['/booking']">click here</a> to view the details.</p>
    <p class="m-0 body-b1 result-title">Showing {{searchListing.length }}  {{searchListing.length > 1 ? 'results' : 'result'}}</p>
    <ng-container  *ngFor="let list of searchListing; let bkIndex = index">
        <div class="border-separator"  *ngFor="let lists of list.arVessel; let bkIndexs = index">
        <div class="ShowingResultIn" [ngClass]="{'border-separator':list.arVessel[0].vesselName != lists.vesselName && searchListing.idBooking == list.idBooking && list.arVessel[0].idVoyageNumber != lists.idVoyageNumber}" *ngFor="let vessel of lists.arPorts ;let i = index;let first = first;let last = last">
            <div class="BookingIdCol">
                <ng-container *ngIf="bkIndexs == 0 && i==0">
                    <span class="body-b">Booking ID</span>
                    <p class="m-0 font-20 fw-700 cursor"><span (click)="navigateDetails(list.idBooking , list.fleet)" [ngClass]="{HighlighLight:list?.idBooking == selectedTag.searchKeyword}"> {{list?.idBooking}} </span></p>
                </ng-container>
            </div>
            <div class="RightColMain" >

                <div class="InnerRow">
                    <div class="heading-main" *ngIf="i == 0">
                        <p class="m-0 Primaryheding section-title">
                            Bookings
                        </p>
                    </div>
                    <div class="col-div-main">
                        <div class="col-cmn-hld">
                            <div class="col-cmn column-first">
                                <span class="body-b">Ship / Voyage no</span>
                                <p class="m-0 body-b1"><span [ngClass]="{HighlighLight:lists.vesselName == selectedTag.searchKeyword}">{{lists.vesselName|titlecase}}</span>  / {{vessel.idVoyageNumber}}</p>
                            </div>
                            <div class="col-cmn column-two"> 
                                    <span class="body-b">Cargoes</span>
                                    <div class="tooltipHvr">

                                        <snl-tooltip class="width-fc df-c" [position]="'bottom'">
                                            <span class="ValueTxt Btm-dashed-bdr" (click)="mobileCargoesAddCall($event,vessel.arCargoes)"> 
                                                {{vessel.arCargoes.length }}
                                            </span>
                                            <div class="snl-tooltip-custmzd static-tooltip gsearch-cargo" tooltipContent>
                                                <div class="col-main">
                                                    <div class="header-tp">
                                                        <span class="col-doc body-b2">Cargoes</span>                                                    
                                                    </div>
                                                    <div class="body-tp body-b">
                                                        <div class="body-tp-row" *ngFor="let cargo of vessel.arCargoes">
                                                            <span class="col-doc mw-100">{{ cargo.name | titlecase }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </snl-tooltip>

                                    </div>
                            </div>
                        </div>    
                        <div class="col-cmn column-three">
                            <span class="body-b">Load - discharge port</span>
                            <p class="m-0 body-b1"> <span [ngClass]="{HighlighLight:vessel.loadPortName == selectedTag.searchKeyword}">{{vessel.loadPortName|titlecase}} </span> - <span [ngClass]="{HighlighLight:vessel.dischargePortName == selectedTag.searchKeyword}"> {{vessel.dischargePortName|titlecase}}</span> </p>
                        </div>
                    </div>
                </div>

                <div class="InnerRow">
                    <div class="col-div-main">
                        <div class="col-cmn-hld">
                            <div class="col-cmn column-first">
                                <span class="body-b">Shipment status</span>
                                <p class="m-0 body-b1">{{vessel.vesselStatus}} </p>
                            </div>
                            <div class="col-cmn column-two">
                                <ng-container *ngIf="navType =='local'">
                                    <span class="body-b">ETA</span>
                                    <p  class="m-0 body-b1">{{list.bookingStatus == 'Completed' ? '&mdash;' : vessel.etaDate  | date:'d MMM y':'UTC' }}</p>
                                </ng-container> 
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="InnerRow" *ngIf=" (vessel?.docCount?.totalLoadDocsCount  > 0 || vessel?.docCount?.totalDischargeDocsCount > 0) && isDocumentationAccess" >
                    <div class="heading-main">
                        <p class="m-0 Primaryheding section-title">
                            Documentation
                        </p>
                    </div>
                    <div class="col-div-main">
                        <div class="col-cmn-hld">
                            <div class="col-cmn column-first" *ngIf="vessel.docCount?.totalLoadDocsCount  > 0"> 
                                <span class="body-b">Load docs</span>
                                <div class="tooltipHvr"> 

                                <snl-tooltip class="width-fc df-c" [position]="'bottom'">
                                    <span class="ValueTxt Btm-dashed-bdr" (click)="mobileDocumentAddCall($event,vessel.arCargoes,'load')"> 
                                        {{vessel.docCount?.totalLoadDocsCount}}
                                    </span>
                                    <div class="snl-tooltip-custmzd static-tooltip" tooltipContent>
                                        <div class="col-main">
                                            <div class="d-flex justify-content-between">
                                                <span class="col-doc body-b2">Cargoes</span>
                                                <span class="col-load body-b2">Docs</span> 
                                            </div>
                                            <div *ngFor="let docs of vessel.arCargoes">
                                                <div class="body-tp-row body-b justify-content-between">
                                                    <span class="col-doc mw-100">{{docs.name|titlecase}}</span>
                                                    <span class="col-load">{{docs.loadDocCount}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </snl-tooltip>


                                </div>
                            </div>
                            <div class="col-cmn column-first" *ngIf="vessel.docCount?.totalLoadDocsCount  == 0"> 
                                <span class="body-b2">Load docs</span>
                                <div class="tooltipHvr"> 
                                    {{vessel.docCount?.totalLoadDocsCount}}
                                </div>
                            </div>

                            <div class="col-cmn column-two" *ngIf="vessel.docCount?.totalDischargeDocsCount > 0"> 
                                    <span class="body-b">Discharge docs</span>
                                    <div class="tooltipHvr">

                                        <snl-tooltip class="width-fc df-c" [position]="'bottom'">
                                            <span class="ValueTxt Btm-dashed-bdr" (click)="mobileDocumentAddCall($event,vessel.arCargoes,'discharge')"> 
                                                {{vessel.docCount?.totalDischargeDocsCount}}
                                            </span>
                                            <div class="snl-tooltip-custmzd static-tooltip" tooltipContent>
                                                <div class="col-main">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="col-doc body-b2">Cargoes</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <span class="col-load body-b2">Docs</span> 
                                                    </div>
                                                    <div class="body-tp mt-2" *ngFor="let docs of vessel.arCargoes">
                                                        <div class="body-tp-row justify-content-between body-b">
                                                            <span class="col-doc mw-100">{{docs.name|titlecase}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span class="col-load">{{docs.dischargeDocsCount}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </snl-tooltip>

                                    </div>
                            </div>
                            <div class="col-cmn column-two" *ngIf="vessel.docCount?.totalDischargeDocsCount == 0"> 
                                <span class="body-b">Discharge docs</span>
                                <div class="tooltipHvr"> 
                                        {{vessel.docCount?.totalDischargeDocsCount}}
                                </div>
                            </div>
                            </div> 
                        </div>
                </div>
                <div class="InnerRow" *ngIf="!list.isTSSFlag && !list.isBallestNegativeFuel && !(list.CO2Emission == null || list.CO2Emission == 0) && isSustainabilityAccess && last">
                    <div class="heading-main">
                        <p class="m-0 Primaryheding section-title">
                            Sustainability
                        </p>
                    </div>
                    <div class="col-div-main">
                        <div class="col-cmn-hld">    
                            <div class="col-cmn column-first">
                                <span class="body-b">Shipment status</span>
                                <p class="m-0 body-b1">Completed</p>
                            </div>
                            <div class="col-cmn column-two">
                            </div>
                        </div>    
                        <div class="col-cmn column-three LinkTxt">
                            <a href="JavaScript:void(0)" (click)="downloadBookingCase2(list.idBooking , list.fleet)" class="Primaryheding section-title">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.25 13.5H2.75C2.3125 13.5 2 13.8125 2 14.25C2 14.6875 2.3125 15 2.75 15H13.25C13.6562 15 14 14.6875 14 14.25C14 13.8438 13.6562 13.5 13.25 13.5ZM11.4375 6.5L8.75 9.375V1.78125C8.75 1.34375 8.40625 1 8 1C7.5625 1 7.25 1.34375 7.25 1.78125V9.375L4.53125 6.5C4.375 6.34375 4.1875 6.28125 4 6.28125C3.8125 6.28125 3.625 6.34375 3.46875 6.46875C3.15625 6.75 3.15625 7.21875 3.4375 7.53125L7.4375 11.7812C7.71875 12.0938 8.25 12.0938 8.53125 11.7812L12.5312 7.53125C12.8125 7.21875 12.8125 6.75 12.5 6.46875C12.1875 6.1875 11.7188 6.1875 11.4375 6.5Z"
                                        fill="#008ECF" />
                                </svg>
                                Download SCC form 
                            </a>
                        </div>
                    </div>
                </div>

                <!--Restricted Sustainability Access-->
                <div class="InnerRow" *ngIf="!isSustainabilityAccess">
                    <div class="heading-main">
                        <p class="m-0 Primaryheding section-title">Sustainability</p>
                    </div>
                    <div class="col-div-main">
                        <p class="body-b1 m-0 RestrictedIcon"><snl-icon icon="fa fa-eye-slash"></snl-icon> Restricted Access</p>
                    </div> 
                </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<!-- Showing Result Area End -->
