import { Component } from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent  {
  showMonkeySurvey: boolean;
  userId:any;
  constructor(public navigationService : NavigationService ) { 
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers About');
    let isLogin = localStorage.getItem('userInfo');
    if(isLogin){
      this.getUserInfo();
    }
  }

  getUserInfo(){
    this.navigationService.getUserInfo().subscribe((res: any) => {
        if (res?.data?.surveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) {
          this.navigationService.isSurveyMonkey = true;
          this.showMonkeySurvey = true;
          this.userId = res.data.user_id;
        }
    })
  }
}
