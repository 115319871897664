export const environment = {
    production: true,
    API_BASE_URL: 'https://api.stoltuat.com/stdigital',
    B2C_CLIENTID:'69232c76-7208-44bd-a2fe-2d9a57f1d55f',
    AUTHORITY:'https://stoltuatb2c.b2clogin.com/stoltuatb2c.onmicrosoft.com/B2C_1A_STDIGITAL_SIGNUP_SIGNIN',
    KNOWNAUTHORITIES:['stoltuatb2c.b2clogin.com'],
    USERSCOPE:'https://stoltuatb2c.onmicrosoft.com/9b1f8ff8-1b2f-498b-8dee-f1fe6ac46bf1/Read',
    GRAPH_ENDPONT:'https://graph.microsoft.com/v1.0/me',
    REDIRECT_URI:'https://stdigital.stoltuat.com/berths-schedules',
    POST_LOGOUT_REDIRECT_URI:'https://stdigital.stoltuat.com',
    FRONTEND_GOOGLE_MAPS:'AIzaSyD-JpB5eMpvA___hJrmGB0I95DzpI8w37w',
    AUTH_SERVICE_BASE_NAME:'/auth',
    BOOKING_SERVICE_BASE_NAME:'/booking',
    MASTER_SERVICE_BASE_NAME:'/master',
    SUSTAINABILITY_SERVICE_BASE_NAME:'/sustainability',
    VESSELTRACKING_SERVICE_BASE_NAME:'/vessel-tracking',
    CAPTCHAKEY:'6LcX0KIkAAAAAJVy9RDU_kdxcPYAWnhvwnqgSHvq',
    appInsights: {
      instrumentationKey: 'de61012f-26b0-48f0-bee5-c51881b071d8'
    },
    navType:'uat',
    googleAnalytic:'uat',
    googleAnalyticKey:'G-MG5CZ9S2KW', 
    googleTagManager:'GTM-MBZM57T',
    googleAnalyticUAKey:'UA-225469168-1',
    DOCUMENTATION_BASE_URL: 'https://app.laytimedesk.com/pdms-api/external/download',
    DOCUMENTATION_POC_KEY:"ebef5516-2505-4229-ae4b-c11aacc380e0",
    POOLING_PORTAL_URL: "https://stpoolingportal.stoltuat.com/dashboard",
    apiurl:'https://stdigital.stoltuat.com',
    DIMENSIONS:{
    'dimension1': 'userCompany',
    'dimension2': 'userName',
    'dimension3': 'userType'},
    GLOBALCOMPANY : 5943082716,
    IP_URL:"https://ipwhois.app/json/",
    USERSEMAIL : ['HNP@stolt.com','ahg@stolt.com','ish@stolt.com','GAI@STOTL.COM','HML@stolt.com','NSA@STOLT.COM','VTY@STOLT.COM','RKM@STOLT.COM','AGP@STOLT.COM',"ACL@STOLT.COM",'IRF@STOLT.COM','OHO@STOLT.COM','OIS@STOLT.COM,"MIR@STOLT.COM','NGI@STOLT.COM','YLM@STOLT.COM'],
    bookingFilterRegex:/ *\([^)]*\) */g,
    googleVerificationKey:"test",
    SURVEY_MONKEY_WEBLINK: 'https://www.surveymonkey.com/r/TFPG5RC',
    secretKeyOtp : '0866cc77866a65d5ca59533f033b7e8f',
    FULL_CANLENDAR_KEY: '0795550318-fcs-1738947948'
  };
