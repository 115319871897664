import { Component, OnInit,Input, OnChanges } from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';
@Component({
  selector: 'app-day-delay',
  templateUrl: './day-delay.component.html',
  styleUrls: ['./day-delay.component.scss']
})
export class DayDelayComponent implements OnInit ,OnChanges {
  @Input() val :any;
  constructor( private navigationService: NavigationService) { }
  delayObj: any[] =[];
  delayVal:any;
  ngOnInit(): void {
    this.delayVal = this.delay(this.val)
    let d_lay:any = localStorage.getItem('d_lay');
    if (d_lay) {
      this.setDelayData(d_lay);
    }else{
      this.navigationService.getUserInfo().subscribe((res:any) =>
      {
       const profData = res.data;
         d_lay = {'min_days':profData.min_delay_days,'max_days':profData.max_delay_days}
        localStorage.setItem('d_lay',JSON.stringify(d_lay));
        const d_layVal = localStorage.getItem('d_lay');
        this.setDelayData(d_layVal);
     })
    }
  }

  ngOnChanges(){
    this.delayVal = this.delay(this.val)
  }

  delay(val:any) {
    if(val>0 && val<1) {
    return val > 0.5 ? {val:1,type:'day',approx:Math.round(val)} : {val:Math.round(val*24),type:Math.round(val*24) > 1 ? 'hours' : 'hour',approx:Math.round(val)}
    }
    else if(val < 0 && val > -1){
      return val < -0.5 ? {val:-1,type:'day',approx:Math.round(val)} : {val:Math.round(val*24),type:Math.round(val*24) >= -1 ? 'hour' : 'hours',approx:Math.round(val)} 
    }else{
      return this.refactorDelay(val);
    }  
   }

   refactorDelay(val:any){
    return {val:Math.round(val),type:Math.abs(Math.round(val)) > 1  ? 'days' :'day',approx:Math.round(val)};
   }

   setDelayData(d_lay:any){
    const d_layData= JSON.parse(d_lay);
    for (let index = 0; index < 3; index++) {
      if(index == 0){
       this.delayObj.push({'min': -365, 'max':d_layData.min_days,cls:'success'})
      }
      if(index == 1){ 
       this.delayObj.push({'min': d_layData.min_days, 'max':Number(parseInt(d_layData.max_days)+0.1),cls:'progress'})
      } 
      if(index == 2){
       this.delayObj.push({'min': Number(parseInt(d_layData.max_days)+0.1), 'max':365,cls:'error'})
      } 
      
    }
   }
}
