import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Sortingfield } from 'src/app/enums/sortingfield';
import { NavigationService } from 'src/app/services/navigation.service';
import { PublicPagesService } from 'src/app/services/public-pages.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-snits',
  templateUrl: './snits.component.html',
  styleUrls: ['./snits.component.scss']
})
export class SnitsComponent implements OnInit {
  frequentlyPort: any = [];
  frequentlyCargoes: any[] = []
  frequentlyPortMini: any[] = [];
  frequentlyCargoesMini: any[] = []
  globalFleetData: any[] = []
  frequentlyPortSearch: any;
  frequentlyCargoesSearch: any;
  globalFleet: any = [];
  snitsFleetItem: any;
  sigleFleetMapPopUp: boolean = false;
  isSkelton = false;
  sortingFieldName = '';
  sortingFieldType = '';
  sortInprogress = {
    vsl_name: { 'isActive': false, 'isOpen': false, 'value': 'asc' },
    dwt: { 'isActive': false, 'isOpen': false, 'value': 'asc' },
  }
  searchlength: boolean;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchInputCargo') searchInputcargo: ElementRef;
  searchlengthCargo: boolean;
  portSkelton:boolean = true;
  portlenght: any;
  cargoLength: any;
  isShowMore: boolean = false;
  pageTrackingData:any = {}
  showMonkeySurvey: boolean;
  userId:any;
  constructor(public publicPagesService: PublicPagesService,
    public navigationService: NavigationService,
    private _SeoService: SeoService) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers Fleet information');
    let isLogin = localStorage.getItem('userInfo');
    if(isLogin){
      this.getUserInfo();
    }
  }

  ngOnInit(): void {
    this.addMataTags();
    this.getFrequentlyCargo()
    this.getFrquentlyPortsCargoesSearch()
    this.getGlobalFleets()
  }

  getUserInfo(){
    this.navigationService.getUserInfo().subscribe((res: any) => {
        if (res?.data?.surveyOpenCount < 3 && !this.navigationService.isSurveyMonkey && !res?.data?.isSurveySubmitted) {
          this.navigationService.isSurveyMonkey = true;
          this.showMonkeySurvey = true;
          this.userId = res.data.user_id;
        }
    })
  }

  getFrequentlyCargo() {
    this.publicPagesService.getFrquentlyPortsCargoes().subscribe({next:(res: any) => {
      this.portSkelton = false;
      let LoadData = res.data;
      this.frequentlyPort = LoadData.ports;
      this.portlenght = this.frequentlyPort.map((it:any)=>it.ports).flat().length.toString();
      this.frequentlyCargoes = LoadData.cargoes;
      this.cargoLength = this.frequentlyCargoes.length.toString();
      this.frequentlyPortMini = this.frequentlyPort;
      this.frequentlyCargoesMini = this.frequentlyCargoes;
    }, error:err => {
      this.portlenght = 0;
      this.cargoLength = 0;
      this.portSkelton  = false;
      this.navigationService.errorOccure(err);
    }})
  }

  getFrquentlyPortsCargoesSearch() {
    this.publicPagesService.getFrquentlyPortsCargoesSearch().subscribe({next:(res: any) => {
      console.log("search::",res)
      let searchdata = res.data;
      this.frequentlyPortSearch = searchdata.ports;
      this.frequentlyCargoesSearch = searchdata.cargo
    }, error:err => {
      this.frequentlyPortSearch = [];
      this.frequentlyCargoesSearch = [];
      this.navigationService.errorOccure(err);
    }})
  }

  getGlobalFleets() {
    this.publicPagesService.getGlobalFleets().subscribe({next:(res: any) => {
      console.log("res>>>>>>",res)
      this.isSkelton = true;
      this.globalFleet = (res && res.data && res.data.result) ? (res.data.result) : [];
      console.log("globalFleet:::",this.globalFleet)
    }, error:err => {
      this.isSkelton = false;
      this.globalFleet = [];
      this.navigationService.errorOccure(err);
    }})
  }



  cleareSearch(type: any) {
    if (type == 'port') {
      this.searchInput.nativeElement.value = '';
      this.searchlength = false
      this.frequentlyPort = this.frequentlyPortMini;
    } else {
      this.searchInputcargo.nativeElement.value = '';
      this.searchlengthCargo = false
      this.frequentlyCargoes = this.frequentlyCargoesMini;
    }

  }

  customSearch(event: any, type: any) {
    let wordPort = (event.target as HTMLInputElement).value.trim();
    if (type == 'port') {
      this.searchlength = wordPort.length > 1 ? true : false
      if (wordPort && wordPort.length > 1) {
        let portdata = this.frequentlyPortSearch.map((it: any) => ({ '_id': it.ports.replace(/ *\([^)]*\) */g, "") }));
        this.frequentlyPort = portdata.filter((it: any) => it._id.toLowerCase().includes(wordPort.toLowerCase()));
      } else {
        this.frequentlyPort = this.frequentlyPortMini;
      }
    } else {
      this.searchlengthCargo = wordPort.length > 1 ? true : false
      if ((wordPort != undefined && wordPort != "undefined" && wordPort != null && wordPort != "null" && wordPort != "") || wordPort.length > 2) {
        let cargodata = this.frequentlyCargoesSearch.map((it: any) => ({ '_id': it.cargoName.replace(/ *\([^)]*\) */g, "") }));
        this.frequentlyCargoes = cargodata.filter((it: any) => it._id.toLowerCase().includes(wordPort.toLowerCase()));
      } else {
        this.frequentlyCargoes = this.frequentlyCargoesMini;
      }
    }

  }

  setLocation(it: any) {
    this.snitsFleetItem = {};
    this.snitsFleetItem = it;
    this.snitsFleetItem.last_latitude = parseFloat(it.last_latitude);
    this.snitsFleetItem.last_longitude = parseFloat(it.last_longitude);
    this.sigleFleetMapPopUp = true;
  }


  popUpMouseLeaveClose(val: any) {
    switch (val) {
      case Sortingfield.vsl_name:
        this.sortInprogress.vsl_name.isOpen = false;
        return;
      case Sortingfield.dwt:
        this.sortInprogress.dwt.isOpen = false;
        return;
      default:
        return;
    }
  }

  fetchSortingType(obj: any) {
    console.log("obj>>>>",obj)
    this.sortingFieldType = obj.value;
    switch (obj.col) {
      case Sortingfield.vsl_name:
        this.sortInprogress.vsl_name.isActive = true;
        this.sortInprogress.dwt.isActive = false;
        this.sortInprogress.vsl_name.value = this.sortInprogress.vsl_name.isActive ? obj.value : '';
        this.sortingFieldName = Sortingfield.vsl_name;

        return;
      case Sortingfield.dwt:
        this.sortInprogress.dwt.isActive = true;
        this.sortInprogress.vsl_name.isActive = false;
        this.sortInprogress.dwt.value = this.sortInprogress.dwt.isActive ? obj.value : '';
        this.sortingFieldName = Sortingfield.dwt;

        return;
      default:
        return;
    }
  }

  toogleSortingOrder(fieldname: string) {
    console.log("fieldname",fieldname)
    switch (fieldname) {
      case Sortingfield.vsl_name:
        this.sortInprogress.vsl_name.isOpen = !this.sortInprogress.vsl_name.isOpen;
        this.sortInprogress.dwt.isOpen = false;
        return;
      case Sortingfield.dwt:
        this.sortInprogress.dwt.isOpen = !this.sortInprogress.dwt.isOpen
        this.sortInprogress.vsl_name.isOpen = false;
        return;
      default:
        return;
    }
  }

  addMataTags() {
    let metaTags: any = [
      { name: 'keywords', content: this._SeoService.tagsForSnits },
      { name: 'description', content: "Stolt Tankers operates the world’s largest fleet of chemical parcel tankers including barges, inland tankers, and floating storage services in stainless steel tanks across Europe particularly across the Rhine." }
    ]
    this._SeoService.addTags(metaTags);
  }
  
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  showMore(event:any)
  {
    this.isShowMore = event
  }

  ngOnDestroy(){
    this._SeoService.removeTags();
  }



}

