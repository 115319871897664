import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, tap,map } from 'rxjs/operators';
import { Tokens } from '../models/token';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  baseUrl= environment.API_BASE_URL+environment.AUTH_SERVICE_BASE_NAME;

  constructor(private http: HttpClient) {}

  isLoggedIn() {
    return !this.getJwtToken();
  }

 getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  /* ------------login----------------- */
  login(user: { email: string, password: string }): Observable<boolean> {
    return this.http.post<any>(`${this.baseUrl}/auth/login`, user)
      .pipe(
        tap(data => data.jwt && data.refreshToken ?this.doLoginUser( {'jwt':data.jwt ,'refreshToken' :data.refreshToken}): ''),
        map(data => {return data}),
        );
  }

  private doLoginUser( tokens: Tokens) {
    this.storeTokens(tokens);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  /* --------------refresh token ------------- */

  refreshToken() {
    return this.http.post<any>(`${this.baseUrl}/auth/refresh`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.jwt);
    }));
  }
  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

/* ------------------logout ------------------- */
  logout() {
    debugger
    return this.http.post<any>(`${this.baseUrl}/auth/logout`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      map(() => true),
      catchError(error => {
        alert(error.error);
        return of(false);
      }));
  }

  private doLogoutUser() {
    this.removeTokens();
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

}
