import { Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
@Component({
  selector: 'app-notification-time-picker',
  templateUrl: './notification-time-picker.component.html',
  styleUrls: ['./notification-time-picker.component.scss']
})
export class NotificationTimePickerComponent implements OnInit {
  removeSlotFirst: boolean = false;
  removeSlotSecond: boolean = false;
  notificationForm: UntypedFormGroup
  @Input() editRowNotification: any;
  @Output() notifictionTimeEventCallback : EventEmitter<any> = new EventEmitter();
  notifyTime: Date = new Date()
  notifyTime_second: Date = new Date()
  notifyTime_third: Date = new Date()
  hstep = 1;
  mstep = 30;
  sstep = 10;
  hour: any = new Date().getHours()
  minutes: any = new Date().getMinutes();
  second: any = new Date().getSeconds()
  pikerTime: any;
  meridians = [];
  isFormatPm: boolean = true;
  isFormateAm = false
  isFormatPm_second: boolean = true;
  isFormateAm_second = false
  isFormatPm_third: boolean = true;
  isFormateAm_third = false
  timer: any;
  timer_second: any;
  timer_third: any;
  isDisplayPicker: boolean;
  isDisplayPicker_second: boolean;
  isDisplayPicker_third: boolean;
  timeFormate: any;
  timeFormate_second: any;
  timeFormate_third: any;
  changedFormat: any;
  changedFormat_second: any;
  changedFormat_third: any;
  converTime: any;
  converTime_1: any;
  converTime_2: any;
  activeSecondSlot:boolean = false
  activeThirdSlot:boolean = false
  count: number = 0;
  isNotValidate: boolean = false;
  isNotValidate_1:boolean = false
  isNotValidate_2:boolean = false
  timerSlotLength_1:number = 2
  timerSlotLength_2:number = 3
  year = new Date().getFullYear();
  month = new Date().getMonth() + 1;
  day = new Date().getDate();
  getSplitHoursSecond: number=1;
  getSplitHoursThird: number=2;
  getSplitMinutesSecond: number=0;
  getSplitMinutesThird: number=0;
  getSplitHours: number=12;
  getSplitMinutes: number=0;
  IsSlotcountOne: boolean = true;
  IsSlotcountTwo: boolean = true;
  notificationTimeString: string | null;
  notificationTimeString_1: string | null;
  notificationTimeString_2: string | null;
  constructor(private fb: UntypedFormBuilder) {
  }


  ngOnInit(): void {
    if(this.editRowNotification){
      this.UpdateNotificationTimeSlot()
    }else{
      this.addNotificationTimeslot()
    }
    this.getPmAmformate()
  }

  UpdateNotificationTimeSlot(){
    this.notificationForm = this.fb.group({
      notificationTime: [this.editRowNotification.localTime],
      notificationTime_1: [this.editRowNotification.localTime_1],
      notificationTime_2: [this.editRowNotification.localTime_2]
    })

    this.notifyTime = new Date(this.editRowNotification.localTime)
    if(this.editRowNotification.localTime_1){
      this.notifyTime_second = new Date(this.editRowNotification.localTime_1)
    }else{
      this.notifyTime_second.setMinutes(0o0);
      this.notifyTime_second.setHours(1)
    }
    if(this.editRowNotification.localTime_2){
      this.notifyTime_third = new Date(this.editRowNotification.localTime_2)
    }else{
      this.notifyTime_third.setMinutes(0o0);
      this.notifyTime_third.setHours(2)
    }

    if(this.editRowNotification.localTime_1){
      this.activeSecondSlot = true
    }
    if(this.editRowNotification.localTime_2){
      this.activeThirdSlot = true
    }
    this.converTime = this.editRowNotification.localTime ? this.editRowNotification.localTime : new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
    this.converTime_1 =this.editRowNotification.localTime_1 ? this.editRowNotification.localTime_1 : new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00')
    this.converTime_2 =this.editRowNotification.localTime_2 ? this.editRowNotification.localTime_2 : new Date(this.year + "-" + this.month + "-" + this.day + " " + 2 + ':' + '00')
   
    this.timer = this.getTimeHourMin(1)
    this.timer_second = this.getTimeHourMin(2)
    this.timer_third = this.getTimeHourMin(3)
    this.timeFormate = this.timer.formate
    this.timeFormate_second = this.timer_second.formate
    this.timeFormate_third = this.timer_third.formate
  }

  addNotificationTimeslot(){
    let isdefaultTIme = new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
    this.notificationForm = this.fb.group({
      notificationTime: [isdefaultTIme],
      notificationTime_1: [''],
      notificationTime_2: ['']
    })

    this.notifyTime.setMinutes(0o0);
    this.notifyTime.setHours(12)
    this.notifyTime_second.setMinutes(0o0);
    this.notifyTime_second.setHours(1)
    this.notifyTime_third.setMinutes(0o0);
    this.notifyTime_third.setHours(2)

    let getTime = '24' + ':' + this.notifyTime.getMinutes() + ':' + this.second;
    let getTime_1 = '01' + ':' + this.notifyTime_second.getMinutes() + ':' + this.second;
    let getTime_2 = '02' + ':' + this.notifyTime_third.getMinutes() + ':' + this.second;
    this.timer = this.formatTime(getTime)
    this.timer_second = this.formatTime(getTime_1)
    this.timer_third = this.formatTime(getTime_2)
    this.timeFormate = this.timer.formate;
    this.timeFormate_second = this.timer_second.formate;
    this.timeFormate_third = this.timer_third.formate;
    this.changedFormat = this.timer.formate;
    this.changedFormat_second = this.timer_second.formate;
    this.changedFormat_third = this.timer_third.formate;

    this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
    this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00')
    this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 2 + ':' + '00') 
  }

  getPmAmformate() {
    if (this.timer.formate == 'PM') {
      this.isFormatPm = true
      this.isFormateAm = false
    } else {
      this.isFormatPm = false
      this.isFormateAm = true
    }
    if (this.timer_second.formate == 'PM') {
      this.isFormatPm_second = true
      this.isFormateAm_second = false
    } else {
      this.isFormatPm_second = false
      this.isFormateAm_second = true
    }

    if (this.timer_third.formate == 'PM') {
      this.isFormatPm_third = true
      this.isFormateAm_third = false
    } else {
      this.isFormatPm_third = false
      this.isFormateAm_third = true
    }
  }



  displayPicker(type: any, event: any) {
    if (type == 1) {
      this.isDisplayPicker = !this.isDisplayPicker ? true : false;
      this.isDisplayPicker_second = false
      this.isDisplayPicker_third = false
    } else if (type == 2) {
      this.isDisplayPicker_second = !this.isDisplayPicker_second ? true : false
      this.isDisplayPicker = false
      this.isDisplayPicker_third = false
    } else {
      this.isDisplayPicker_third = !this.isDisplayPicker_third ? true : false
      this.isDisplayPicker = false
      this.isDisplayPicker_second = false
    }

  }

  applyTime(type: any) {
    let jsonObj_1 = this.notificationForm.get('notificationTime')?.value
    let jsonObj_2 = this.notificationForm.get('notificationTime_1')?.value
    let jsonObj_3 = this.notificationForm.get('notificationTime_2')?.value
    this.refactorSlotType(type)
    this.refactorTimeFormateChange(type)
     if (this.timeFormate == 'PM'){
      this.changeTimeFormatePmFirst(type,jsonObj_1)
     }else{
      this.changeTimeFormateAmFirst(type)
    }
     if(this.timeFormate_second == 'PM'){
      this.changeTimeFormatePmSecond(type,jsonObj_2)
    }else{
      this.changeTimeFormateAmSecond(type)
    }

    if(this.timeFormate_third == 'PM'){
      this.changeTimeFormatePmThird(type,jsonObj_3,jsonObj_2)
     
    }else{
      this.changeTimeFormateAmThird(type)
    }

    this.notificationForm.get('notificationTime')?.patchValue(this.converTime)
    if(this.activeSecondSlot){
      this.notificationForm.get('notificationTime_1')?.patchValue(this.converTime_1)
    }
    this.notificationForm.get('notificationTime_2')?.patchValue(this.converTime_2)

    this.notifictionTimeEventCallback.emit({activeSecondSlot:this.activeSecondSlot,activeThirdSlot:this.activeThirdSlot,isTimeDuplicate:(this.isNotValidate || this.isNotValidate_1 || this.isNotValidate_2) ? true : false,data:this.notificationForm.value})
  }

  refactorSlotType(type:any){
    let jsonObj_1 = this.notificationForm.get('notificationTime')?.value
    let jsonObj_2 = this.notificationForm.get('notificationTime_1')?.value
    let jsonObj_3 = this.notificationForm.get('notificationTime_2')?.value
    let controllerTime: any
   if (type == 1) {
      controllerTime =jsonObj_1
    } else if (type == 2) {
      controllerTime = jsonObj_2
    } else {
      controllerTime = jsonObj_3
    }
    let getMinuts = controllerTime.getMinutes()
    this.pikerTime = new Date()
    this.hour = this.pikerTime.getHours();
    this.minutes = getMinuts;
    this.second = this.pikerTime.getSeconds();
    this.isDisplayPicker = false;
    this.isDisplayPicker_second = false;
    this.isDisplayPicker_third = false
  }

  refactorTimeFormateChange(type:any){
    if (type == 1) {
      this.timer = this.getTimeHourMin(type)
      this.timeFormate = (!this.changedFormat) ? this.timer.formate : this.changedFormat;
    } else if (type == 2) {
      this.timer_second = this.getTimeHourMin(type)
      this.timeFormate_second = (!this.changedFormat_second) ? this.timer_second.formate : this.changedFormat_second;
    } else {
      this.timer_third = this.getTimeHourMin(type)
      this.timeFormate_third = (!this.changedFormat_third) ? this.timer_third.formate : this.changedFormat_third;
    }
  }

  changeTimeFormatePmFirst(type:any,jsonObj_1:any){
    if (type == 1) {
      let newDate = new Date(jsonObj_1)
      const convertTime12to24 = (time12h: any) => moment(time12h, 'hh:mm:ss a').format('HH:mm:ss');
      let TwentyFOur = convertTime12to24(this.pikerTime.getHours() + ':' + newDate.getMinutes() + ':' + this.pikerTime.getSeconds() + ' ' + 'PM')
      let split = TwentyFOur.split(":")
      this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + split[0] + ':' + split[1] + ':' + split[2])
      this.assignValueOnFormatPM(type)
    }
  }

  changeTimeFormateAmFirst(type:any){
    let splitHour
    if (type == 1) {
      splitHour = this.timer.HourMin.split(':');
      if (splitHour[0] == '12' && splitHour[1] == '00') {
        this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
      } else if (splitHour[0] == '12' && splitHour[1] == '30') {
        this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + '00' + ':' + 30)
      } else {
        this.converTime = new Date(this.year + "-" + this.month + "-" + this.day + " " + splitHour[0] + ':' + splitHour[1])
      }
    }
    this.assignValueOnFormatAM(type)
  }

  changeTimeFormatePmSecond(type:any,jsonObj_2:any){
    if (type == 2) {
      const convertTime12to24 = (time12h: any) => moment(time12h, 'hh:mm:ss a').format('HH:mm:ss');
      let TwentyFOur_1 = convertTime12to24(jsonObj_2.getHours() + ':' + jsonObj_2.getMinutes() + ':' + this.pikerTime.getSeconds() + ' ' + 'PM')
      let split = TwentyFOur_1.split(":")
      this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + split[0] + ':' + split[1] + ':' + split[2])
      this.assignValueOnFormatPM(type)
     }
  }

  changeTimeFormateAmSecond(type:any){
    if (type == 2) {
      let splitHour_1:any
      splitHour_1 = this.timer_second.HourMin.split(':');
      if (splitHour_1[0] == '12' && splitHour_1[1] == '00') {
        this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
      } else if (splitHour_1[0] == '12' && splitHour_1[1] == '30') {
        this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + '00' + ':' + 30)
      } else {
        this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + splitHour_1[0] + ':' + splitHour_1[1])
      }
      this.assignValueOnFormatAM(type)
    }
  }

  changeTimeFormatePmThird(type:any,jsonObj_3:any,jsonObj_2:any){
    if (type == 3) {
      if(jsonObj_2){
        if(this.timeFormate_third == 'PM' && this.timeFormate_second == 'PM'){
        let obj_2 = new Date(jsonObj_2)
        const convertTime12to24_1 = (time12h: any) => moment(time12h, 'hh:mm:ss a').format('HH:mm:ss');
        let TwentyFOur_1 = convertTime12to24_1(obj_2.getHours() + ':' + obj_2.getMinutes() + ':' + 0 + ' ' + 'PM')
        let split_1 = TwentyFOur_1.split(":")
        this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + split_1[0] + ':' + split_1[1] + ':' + split_1[2])
        }
      }
      const convertTime12to24 = (time12h: any) => moment(time12h, 'hh:mm:ss a').format('HH:mm:ss');
      let TwentyFOur_2 = convertTime12to24(jsonObj_3.getHours() + ':' + jsonObj_3.getMinutes() + ':' + jsonObj_3.getSeconds() + ' ' + 'PM')
      let split = TwentyFOur_2.split(":")
      this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + split[0] + ':' + split[1] + ':' + split[2])
      this.assignValueOnFormatPM(type)
      }
  }

  changeTimeFormateAmThird(type:any){
    if (type == 3) {
      let splitHour_2:any
      splitHour_2 = this.timer_third.HourMin.split(':');
      if (splitHour_2[0] == '12' && splitHour_2[1] == '00') {
        this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 24 + ':' + '00')
      } else if (splitHour_2[0] == '12' && splitHour_2[1] == '30') {
        this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + '00' + ':' + 30)
      } else {
        this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + splitHour_2[0] + ':' + splitHour_2[1])
      }
      this.assignValueOnFormatAM(type)
    }
  }

  assignValueOnFormatPM(type: any) {
    let formdata = this.notificationForm.value;
    if (type == 1) {
      formdata['notificationTime'] = this.converTime ? this.converTime.toString() : '';
      this.notifyTime = new Date(formdata['notificationTime'])
      this.ValidateDuplicateTime_1(type)
    } else if (type == 2) {
      formdata['notificationTime_1'] = this.converTime_1 ? this.converTime_1.toString() : '';
      this.notifyTime_second = new Date(formdata['notificationTime_1'])
      this.ValidateDuplicateTime_2(type)
    } else {
      formdata['notificationTime_2'] = this.converTime_2 ? this.converTime_2.toString() : '';
      this.notifyTime_third = new Date(formdata['notificationTime_2'])
      this.ValidateDuplicateTime_3(type)
     
    }
  }

  ValidateDuplicateTime_1(type:any){
   
    this.isNotValidate = false
    if(type ==1){
      this.extractTimeFromDate()
      if(this.notificationTimeString == this.notificationTimeString_1){
        this.isNotValidate = true
        this.isNotValidate_1 = false
      }else{
        this.isNotValidate_1 = false
      }

      if(this.activeThirdSlot){
        if(this.notificationTimeString == this.notificationTimeString_2){
          this.isNotValidate = true
          this.isNotValidate_2 = false
        }
      }
      
      this.refactorValidation_1()
    }
  }

  refactorValidation_1(){
    if(this.activeSecondSlot || this.activeThirdSlot)
      {
        if(this.notificationTimeString_1 == this.notificationTimeString_2){
          this.isNotValidate_1 = true
        }
      }
     
      if(this.isNotValidate_1 && this.isNotValidate_2){
        this.isNotValidate_1 = false
      }

      if(this.notificationTimeString == this.notificationTimeString_1 && this.notificationTimeString_1 == this.notificationTimeString_2 && this.notificationTimeString == this.notificationTimeString_2){
        this.isNotValidate = true
        this.isNotValidate_1 =false
        this.isNotValidate_2 =false
      }

      if (this.notificationTimeString !== this.notificationTimeString_1 && this.notificationTimeString !== this.notificationTimeString_2 && this.notificationTimeString_1 !== this.notificationTimeString_2) {
        this.isNotValidate_2 =false
      }
  }

  ValidateDuplicateTime_2(type:any){
    this.isNotValidate_1 = false
    if(type == 2){
      this.extractTimeFromDate()
      if(this.notificationTimeString_1 == this.notificationTimeString){
        this.isNotValidate_1 = true
      }
      if(this.activeThirdSlot){
      if(this.notificationTimeString_1 == this.notificationTimeString_2){
        this.isNotValidate_1 = true
      }
    }

    this.refactorValidation_2()
    }
  }

  refactorValidation_2(){
    if(this.notificationTimeString == this.notificationTimeString_2){
      this.isNotValidate_2 = true
    }
    
     if(this.isNotValidate){
      this.isNotValidate = false
    }
    if(this.isNotValidate_1 && this.isNotValidate_2){
      this.isNotValidate_2 = false
    }
  }

  ValidateDuplicateTime_3(type:any){
    this.isNotValidate_2 = false
    if(type == 3){
      this.extractTimeFromDate()
      if(this.notificationTimeString_2 == this.notificationTimeString){
        this.isNotValidate_2 = true
      }

      if(this.notificationTimeString_2 == this.notificationTimeString_1){
        this.isNotValidate_2 = true
        this.isNotValidate_1 = false
      }else{
        this.isNotValidate_1 = false
      }

      if(this.notificationTimeString == this.notificationTimeString_1){        
        this.isNotValidate_1 = true
      }
      this.refactorValidation_3()
    }
  }
 
  refactorValidation_3(){
    if(this.isNotValidate){
      this.isNotValidate = false
    }
    if(this.converTime.toString() == this.converTime_2.toString()){
      this.isNotValidate_2 = true
    }
    if(this.notificationTimeString == this.notificationTimeString_1 && this.notificationTimeString_1 == this.notificationTimeString_2 && this.notificationTimeString == this.notificationTimeString_2){
      this.isNotValidate = false
      this.isNotValidate_1 =false
      this.isNotValidate_2 =true
    }
  }

  extractTimeFromDate(){
    this.notificationForm.get('notificationTime')?.patchValue(this.converTime)
    if(this.activeSecondSlot){
      this.notificationForm.get('notificationTime_1')?.patchValue(this.converTime_1)
    }else{
      this.notificationForm.get('notificationTime_1')?.reset()
    }  

    if(this.activeThirdSlot){
      this.notificationForm.get('notificationTime_2')?.patchValue(this.converTime_2)
    }else{
      this.notificationForm.get('notificationTime_2')?.reset()
    }  

    let notificationValue = this.notificationForm.get('notificationTime')?.value
    let notificationValue_1 = this.notificationForm.get('notificationTime_1')?.value
    let notificationValue_2 = this.notificationForm.get('notificationTime_2')?.value


    let notificationdate =notificationValue ? new Date(notificationValue) : null;
    this.notificationTimeString =notificationdate ? notificationdate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // This will use a 24-hour format
}) : null
    

    let notificationdate_1 =notificationValue_1 ? new Date(notificationValue_1) : null;
    this.notificationTimeString_1 =notificationdate_1 ? notificationdate_1.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // This will use a 24-hour format
}) : null

    let notificationdate_2 =notificationValue_2 ? new Date(notificationValue_2) : null;
    this.notificationTimeString_2 =notificationdate_2 ? notificationdate_2.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false // This will use a 24-hour format
}) : null
  }

  assignValueOnFormatAM(type: any) {
    let formdata = this.notificationForm.value;
    if (type == 1) {
      formdata['notificationTime'] = this.converTime.toString();
      this.notifyTime = new Date(formdata['notificationTime'])
      this.ValidateDuplicateTime_1(type)
    } else if (type == 2) {
      formdata['notificationTime_1'] = this.converTime_1.toString();
      this.notifyTime_second = new Date(formdata['notificationTime_1'])
      this.ValidateDuplicateTime_2(type)
    } else {
      formdata['notificationTime_2'] = this.converTime_2.toString();
      this.notifyTime_third = new Date(formdata['notificationTime_2'])
      this.ValidateDuplicateTime_3(type)
    }
  }

  getTimeHourMin(type?: any) {
    if (type == 1) {
      this.pikerTime = this.notificationForm.value.notificationTime ? new Date(this.notificationForm.value.notificationTime) : new Date();
    } else if (type == 2) {
      this.pikerTime = this.notificationForm.value.notificationTime_1 ? new Date(this.notificationForm.value.notificationTime_1) : this.converTime_1;
    } else {
      this.pikerTime = this.notificationForm.value.notificationTime_2 ? new Date(this.notificationForm.value.notificationTime_2) : this.converTime_2;
    }
    let picMinutes = this.pikerTime.getMinutes()
    this.hour = this.pikerTime.getHours();
    this.minutes = picMinutes

    this.second = this.pikerTime.getSeconds();
    let getTime = this.hour + ':' + this.minutes + ':' + this.second;
    return this.formatTime(getTime);
  }

  formatTime(timeString: any) {
    const [hourString, minute] = timeString.split(":");
    let minutes = (minute.toString().length == 1) ? 0 + minute : minute;
    const hour = +hourString % 24;
    let hours = ((hour % 12 || 12).toString().length == 1) ? '0' + (hour % 12 || 12) : (hour % 12 || 12)
    return { HourMin: hours + ":" + minutes, formate: (hour < 12 ? "AM" : "PM") }
  }

  onFormateChange(event: any, type: any) {
    if (event == 1) {
      this.changedFormat = type
      if (type == 'PM') {
        this.isFormatPm = true;
        this.isFormateAm = false;
      } else {
        this.isFormatPm = false;
        this.isFormateAm = true;
      }
    } else if (event == 2) {
      this.changedFormat_second = type
      if (type == 'PM') {
        this.isFormatPm_second = true;
        this.isFormateAm_second = false;
      } else {
        this.isFormatPm_second = false;
        this.isFormateAm_second = true;
      }
    } else {
      this.changedFormat_third = type
      if (type == 'PM') {
        this.isFormatPm_third = true;
        this.isFormateAm_third = false;
      } else {
        this.isFormatPm_third = false;
        this.isFormateAm_third = true;
      }
    }
  }

  removeSlot(type:any){
    this.count = 0
    if(type == 1){
      this.removeSlotFirst = true
      this.refactotRemoveSlotFirst()
    }else if(type == 2){
      this.removeSlotSecond = true
      this.refactotRemoveSlotSecond()
    }else{
      console.log("####")
    }
 
    this.notifictionTimeEventCallback.emit({activeSecondSlot:this.activeSecondSlot,activeThirdSlot:this.activeThirdSlot,isTimeDuplicate:(this.isNotValidate || this.isNotValidate_1 || this.isNotValidate_2) ? true : false,data:this.notificationForm.value})
  }

  refactotRemoveSlotFirst(){
    console.log("remove second ::::")
    this.timerSlotLength_2 = 2
    this.count =1
    if(!this.activeThirdSlot){
      this.activeSecondSlot = false
    }
    this.activeThirdSlot = false

    if(!this.activeSecondSlot && !this.activeThirdSlot){
      this.count = 0
    }
    this.notificationForm.get('notificationTime_1')?.patchValue(this.converTime_2)
    this.notificationForm.get("notificationTime_2")?.patchValue(this.converTime_1)

    this.notifyTime_second = this.notifyTime_third
    this.timer_second = this.timer_third
    this.timeFormate_second = this.timeFormate_third
    this.isFormatPm_second = this.isFormatPm_third
    this.isFormateAm_second = this.isFormateAm_third
    this.converTime_1 = this.converTime_2
    this.refactorTimeSlot()     
    if(!this.notificationForm.value.notificationTime_1 && !this.notificationForm.value.notificationTime_2){
      this.isNotValidate = false
    }

    if(!this.notificationForm.value.notificationTime_1 || !this.notificationForm.value.notificationTime_2){
      this.isNotValidate = false
    }
   
    if(this.isNotValidate_1){
      this.isNotValidate_1 = false
    }
    if(this.isNotValidate_2){
      this.isNotValidate_2 = false
    }
    if(this.isNotValidate){
      this.isNotValidate = false
    }

    if(this.activeThirdSlot){
      if(this.timer.HourMin == this.timer_third.HourMin && this.timeFormate == this.timeFormate_third){
        this.isNotValidate = true
      }else{
        this.isNotValidate = false
      }
    }
    if(!this.activeSecondSlot && this.activeThirdSlot){
      if(this.timer.HourMin == this.timer_third.HourMin && this.timeFormate == this.timeFormate_third){
        this.isNotValidate = true
      }else{
        this.isNotValidate = false
      }
    }
    this.ValidateDuplicateTime_2(2)
  }

  refactotRemoveSlotSecond(){
    console.log("remove third ::::")
    this.count =1
    this.activeThirdSlot = false
    this.refactorTimeSlot() 
    if(this.isNotValidate_2){
      this.isNotValidate_2 = false
    }
    if(this.isNotValidate_1){
      this.isNotValidate_1 = false
    }
    if(this.isNotValidate){
      this.isNotValidate = false
    }

    if(this.activeSecondSlot){
      if(this.timer.HourMin == this.timer_second.HourMin && this.timeFormate == this.timeFormate_second){
        this.isNotValidate = true
      }else{
        this.isNotValidate = false
      }
    }
  }

  iscallone:any=true
  addMoreSlot(){
    this.notificationForm.get('notificationTime')?.patchValue(this.converTime.toString());

    if(this.editRowNotification && this.activeSecondSlot){
      this.count = 1
    }
    this.count++
    if(this.count ==1){
      this.refectorAddMoreFirstSlot()
    }
    if(this.count ==2){
      this.refectorAddMoreSecondSlot()
    }
    if(this.count >=2){
      this.count =0
    }
    this.notifictionTimeEventCallback.emit({activeSecondSlot:this.activeSecondSlot,activeThirdSlot:this.activeThirdSlot,isTimeDuplicate:(this.isNotValidate || this.isNotValidate_1 || this.isNotValidate_2) ? true : false,data:this.notificationForm.value})
  }

  refectorAddMoreFirstSlot(){
    console.log("slot1111:::")
    this.timerSlotLength_1 = 2
     this.activeSecondSlot = true;
     if(this.editRowNotification){
      let getTime_1 = '01' + ':' + this.notifyTime_second.getMinutes() + ':' + this.second;
      this.timer_second = this.formatTime(getTime_1)
      this.timeFormate_second = this.timer_second.formate;
      this.changedFormat_second = this.timer_second.formate;
      this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00') 
   }else{
    this.refactorFirstTimeSlot()
   }  
   this.ValidateDuplicateTime_2(2)
  }

  refactorFirstTimeSlot()
  {
    if(this.timer.HourMin == "01:00"){
      this.notifyTime_second.setMinutes(0o0);
      this.notifyTime_second.setHours(2)
      let getTime_1 = '02' + ':' + this.notifyTime_second.getMinutes() + ':' + this.second;
      this.timer_second = this.formatTime(getTime_1)
      this.timeFormate_second = this.timer_second.formate;
      this.changedFormat_second = this.timer_second.formate;
      this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 2 + ':' + '00')
    }else{
      this.notifyTime_second.setMinutes(0o0);
      this.notifyTime_second.setHours(1)
      let getTime_1 = '01' + ':' + this.notifyTime_second.getMinutes() + ':' + this.second;
      this.timer_second = this.formatTime(getTime_1)
      this.timeFormate_second = this.timer_second.formate;
      this.changedFormat_second = this.timer_second.formate;
      this.converTime_1 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00') 
    }
  }
  refectorAddMoreSecondSlot(){
    console.log("slot222:::")
    this.activeThirdSlot = true;
    this.timerSlotLength_2 = 3
    this.refactorTimeSlot()
    this.ValidateDuplicateTime_3(3)
  }

  refactorTimeSlot(){
    if(this.timer_second.HourMin == "01:00"){
      this.notifyTime_third = new Date(this.year + "-" + this.month + "-" + this.day + " " + 2 + ':' + '00')
      let getTime_2 = '02' + ':' + 0 + ':' + 0;
      this.timer_third = this.formatTime(getTime_2)
      this.timeFormate_third = this.timer_third.formate;
      this.changedFormat_third = this.timer_third.formate;
      this.isFormatPm_third = false
      this.isFormateAm_third = true
      this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 2 + ':' + '00')
      this.notificationForm.get("notificationTime_2")?.patchValue(this.converTime_2)
    }else{
      this.notifyTime_third = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00')
      let getTime_2 = '01' + ':' + 0 + ':' + 0;
      this.timer_third = this.formatTime(getTime_2)
      this.timeFormate_third = this.timer_third.formate;
      this.changedFormat_third = this.timer_third.formate;
      this.isFormatPm_third = false
      this.isFormateAm_third = true
      this.converTime_2 = new Date(this.year + "-" + this.month + "-" + this.day + " " + 1 + ':' + '00')
      this.notificationForm.get("notificationTime_2")?.patchValue(this.converTime_2)
    }
  }

}
