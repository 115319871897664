import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrontRoutingModule } from './front-routing.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollToTopComponent } from './reusable_components/scroll-to-top/scroll-to-top.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { ShareComponent } from './reusable_components/share/share.component';
import { SharedModuleModule } from './pages/shared-module/shared-module.module';
import { SharedSnlComplibModule } from './shared-material/shared-snl-complib-module';
import { NpsSurveyMonkeyComponent } from './reusable_components/nps-survey-monkey/nps-survey-monkey.component';
import { ShowAnnouncementComponent } from './reusable_components/show-announcement/show-announcement.component';
@NgModule({
  declarations: [
    ScrollToTopComponent,
    ShareComponent,
    NpsSurveyMonkeyComponent,
    ShowAnnouncementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FrontRoutingModule,
    CarouselModule,
    NgScrollbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SharedModuleModule,
    SharedSnlComplibModule,
     MatIconModule, MatInputModule, MatListModule, MatButtonModule, MatGridListModule, MatSnackBarModule
  ],
  exports: [ScrollToTopComponent,CarouselModule,ShareComponent, NpsSurveyMonkeyComponent, ShowAnnouncementComponent]
})
export class FrontModule { }
