import { Pipe, PipeTransform, NgZone, ChangeDetectorRef } from "@angular/core";
@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  private timer: number;
  constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) { }
  transform(value: string) {
    this.removeTimer();
    let d = new Date(value);
    let now = new Date();
    let seconds = Math.floor((now.getTime() - (d.getTime())) / 1000);
    let timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return 0;
    });
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'a few seconds ago';
    } else if (seconds <= 90) {
      return '1 minute ago';
    } else if (minutes <= 59) {
      return minutes + ' minutes ago';
    } else if (minutes >= 60 && hours < 24) {
      return `${hours}${hours > 1 ?  ' hrs'  :  ' hr' } ago`;
    }
    else if (hours >= 24 && hours < 165) {
      return `${days}${days > 1 ? ' days' : ' day'} ago`;
    }
    else if (days <= 7) {
      return days + ' days ago';
    }
    else if (days > 7 && days <= 14) {
      return '2 weeks ago';
    }
    else if (days > 14 && days <= 21) {
      return '3 weeks ago';
    }
    else if (days > 21 && days <= 28) {
      return '4 weeks ago';
    }
    else {
      const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let month = monthName[d.getMonth()];
      let day = d.getDate();
      let year = d.getFullYear();
      return `${day} ${month} ${year}`;
    }
  }
  ngOnDestroy(): void {
    this.removeTimer();
  }
  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = 0;
    }
  }
  private getSecondsUntilUpdate(seconds: number) {
    let min = 60;
    let hr = min * 60;
    let day = hr * 24;
    if (seconds < min) {
      return 2;
    } else if (seconds < hr) {
      return 30;
    } else if (seconds < day) {
      return 300;
    } else {
      return 3600;
    }
  }
}
